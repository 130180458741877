import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { ACCOUNT_FLAG_ALLOWS_ITEMS } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

export default [
    {
        name: "itemCategories.index",
        path: "settings/item-categories",
        component: () => import("@/pages/itemCategoriesSettings/ItemCategoriesIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_ITEMS),
        meta: {
            title: "Categorias e subcategorias",
            accessPermission: "G1",
            description: "Gerencie as categorias e subcategorias de produtos, serviços e mensalidades (MRR).",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Categorias e subcategorias",
                    show: ".*",
                },
            ],
        },
    },
]
