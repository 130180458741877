export default [
    {
        name: "companies.index",
        path: "companies",
        component: () => import("@/pages/companies/CompaniesIndex"),
        meta: {
            pageTitle: "Empresas",
            title: "Empresas",
            mixpanelTrackOrigin: "Listagem de empresas",
        },
    },
]
