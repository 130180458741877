export const C1_CAN_CREATE_CONTACT =             "C1_CAN_CREATE_CONTACT"
export const C1_SCOPE_CAN_CREATE_CONTACT =       "C1_SCOPE_CAN_CREATE_CONTACT"
export const C1_CAN_EDIT_CONTACTS =              "C1_CAN_EDIT_CONTACTS"
export const C1_SCOPE_CAN_EDIT_CONTACT =         "C1_SCOPE_CAN_EDIT_CONTACT"
export const C1_CAN_EDIT_CONTACT_MANAGER =       "C1_CAN_EDIT_CONTACT_MANAGER"
export const C1_SCOPE_CAN_EDIT_CONTACT_MANAGER = "C1_SCOPE_CAN_EDIT_CONTACT_MANAGER"

export const SHOW_EXPORT_DEALS = "SHOW_EXPORT_DEALS"
export const SHOW_EXPORT_PERSONS = "SHOW_EXPORT_PERSONS"
export const SHOW_EXPORT_COMPANIES = "SHOW_EXPORT_COMPANIES"
export const SHOW_EXPORT_PERSONS_LINKED_COMPANIES = "SHOW_EXPORT_PERSONS_LINKED_COMPANIES"
export const SHOW_EXPORT_ACTIVITIES = "SHOW_EXPORT_ACTIVITIES"
export const SHOW_EXPORT_CALLS = "SHOW_EXPORT_CALLS"
export const SHOW_EXPORT_PROPOSALS = "SHOW_EXPORT_PROPOSALS"
export const SHOW_EXPORT_ITEMS = "SHOW_EXPORT_ITEMS"
export const SHOW_EXPORT_REGIONS = "SHOW_EXPORT_REGIONS"
export const SHOW_EXPORT_DATALIST = "SHOW_EXPORT_DATALIST"
export const SHOW_EXPORT_LOST_REASONS = "SHOW_EXPORT_LOST_REASONS"
export const SHOW_EXPORT_CITIES = "SHOW_EXPORT_CITIES"
export const SHOW_EXPORT_DEAL_PRODUCTS = "SHOW_EXPORT_DEAL_PRODUCTS"
export const SHOW_EXPORT_DYNAMIC_RANKING_DEALS = "SHOW_EXPORT_DYNAMIC_RANKING_DEALS"
export const SHOW_EXPORT_DYNAMIC_RANKING_PRODUCTIVITY = "SHOW_EXPORT_DYNAMIC_RANKING_PRODUCTIVITY"
export const SHOW_SCOPE_EXPORT_DEALS = "SHOW_SCOPE_EXPORT_DEALS"
export const SHOW_SCOPE_EXPORT_PERSONS = "SHOW_SCOPE_EXPORT_PERSONS"
export const SHOW_SCOPE_EXPORT_COMPANIES = "SHOW_SCOPE_EXPORT_COMPANIES"
export const SHOW_SCOPE_EXPORT_PERSONS_LINKED_COMPANIES = "SHOW_SCOPE_EXPORT_PERSONS_LINKED_COMPANIES"
export const SHOW_SCOPE_EXPORT_ACTIVITIES = "SHOW_SCOPE_EXPORT_ACTIVITIES"
export const SHOW_SCOPE_EXPORT_CALLS = "SHOW_SCOPE_EXPORT_CALLS"
export const SHOW_SCOPE_EXPORT_PROPOSALS = "SHOW_SCOPE_EXPORT_PROPOSALS"
export const SHOW_SCOPE_EXPORT_ITEMS = "SHOW_SCOPE_EXPORT_ITEMS"
export const SHOW_SCOPE_EXPORT_REGIONS = "SHOW_SCOPE_EXPORT_REGIONS"
export const SHOW_SCOPE_EXPORT_DATALIST = "SHOW_SCOPE_EXPORT_DATALIST"
export const SHOW_SCOPE_EXPORT_LOST_REASONS = "SHOW_SCOPE_EXPORT_LOST_REASONS"
export const SHOW_SCOPE_EXPORT_CITIES = "SHOW_SCOPE_EXPORT_CITIES"
export const SHOW_SCOPE_EXPORT_DEAL_PRODUCTS = "SHOW_SCOPE_EXPORT_DEAL_PRODUCTS"
export const SHOW_SCOPE_EXPORT_DYNAMIC_RANKING_DEALS = "SHOW_SCOPE_EXPORT_DYNAMIC_RANKING_DEALS"
export const SHOW_SCOPE_EXPORT_DYNAMIC_RANKING_PRODUCTIVITY = "SHOW_SCOPE_EXPORT_DYNAMIC_RANKING_PRODUCTIVITY"
export const SHOW_EXPORT_REPORT_GENERAL = "SHOW_EXPORT_REPORT_GENERAL"
export const SHOW_EXPORT_REPORT_FUNNEL_BALANCING = "SHOW_EXPORT_REPORT_FUNNEL_BALANCING"
export const SHOW_EXPORT_REPORT_CONVERSION_RATE = "SHOW_EXPORT_REPORT_CONVERSION_RATE"
export const SHOW_EXPORT_REPORT_FORECAST = "SHOW_EXPORT_REPORT_FORECAST"
export const SHOW_EXPORT_REPORT_SOLD_ITEMS = "SHOW_EXPORT_REPORT_SOLD_ITEMS"
export const SHOW_EXPORT_REPORT_EMAIL_PERFORMANCE = "SHOW_EXPORT_REPORT_EMAIL_PERFORMANCE"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_WEEK = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_WEEK"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_MONTH = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_MONTH"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_USER = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_USER"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_ORIGIN = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_ORIGIN"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_GROUP_ORIGIN = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_GROUP_ORIGIN"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_SEGMENT = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_SEGMENT"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_CNAE = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_CNAE"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_CITY = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_CITY"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_UF = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_UF"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_MICROREGION = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_MICROREGION"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_REGION = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_REGION"
export const SHOW_EXPORT_REPORT_DEAL_PROCESSED_REASON = "SHOW_EXPORT_REPORT_DEAL_PROCESSED_REASON"
export const SHOW_SCOPE_EXPORT_REPORT_GENERAL = "SHOW_SCOPE_EXPORT_REPORT_GENERAL"
export const SHOW_SCOPE_EXPORT_REPORT_FUNNEL_BALANCING = "SHOW_SCOPE_EXPORT_REPORT_FUNNEL_BALANCING"
export const SHOW_SCOPE_EXPORT_REPORT_CONVERSION_RATE = "SHOW_SCOPE_EXPORT_REPORT_CONVERSION_RATE"
export const SHOW_SCOPE_EXPORT_REPORT_FORECAST = "SHOW_SCOPE_EXPORT_REPORT_FORECAST"
export const SHOW_SCOPE_EXPORT_REPORT_SOLD_ITEMS = "SHOW_SCOPE_EXPORT_REPORT_SOLD_ITEMS"
export const SHOW_SCOPE_EXPORT_REPORT_EMAIL_PERFORMANCE = "SHOW_SCOPE_EXPORT_REPORT_EMAIL_PERFORMANCE"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_WEEK = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_WEEK"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_MONTH = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_MONTH"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_USER = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_USER"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_ORIGIN = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_ORIGIN"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_GROUP_ORIGIN = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_GROUP_ORIGIN"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_SEGMENT = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_SEGMENT"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_CNAE = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_CNAE"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_CITY = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_CITY"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_UF = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_UF"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_MICROREGION = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_MICROREGION"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_REGION = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_REGION"
export const SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_REASON = "SHOW_SCOPE_EXPORT_REPORT_DEAL_PROCESSED_REASON"
export const TALK_ATTENDANCE_ALLOWED_WHATSAPP_NUMBERS = "TALK_ATTENDANCE_ALLOWED_WHATSAPP_NUMBERS"
