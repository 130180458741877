import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { ACCOUNT_FLAG_ALLOWS_ITEMS } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

export default [
    {
        name: "items.index",
        path: "settings/items",
        component: () => import("@/pages/itemsSettings/ItemsIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_ITEMS),
        meta: {
            title: "Produtos, serviços ou MRR",
            description: "Gerencie os produtos, serviços e MRRs que serão utilizados na elaboração de propostas de oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Produtos, serviços ou MRR",
                    show: ".*",
                },
            ],
        },
    },
]
