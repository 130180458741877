import environment from "@/shared/environment"
import { ACCOUNT_FLAG_ALLOWS_FORECAST } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

export default [
    {
        name: "forecast.index",
        path: "forecast",
        component: () => import("@/pages/forecast/ForecastIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_FORECAST),
        meta: {
            title: "Forecast",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Forecast",
                    href: "",
                    show: ".*",
                },
            ],
        },
    },
]
