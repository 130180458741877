export const TYPE_RECEIVED = 0
export const TYPE_SENT = 1
export const TYPE_DRAFT = 2
export const TYPE_ARCHIVED = 3
export const TYPE_TRASH = 4

export const STATUS_UNREAD = 0
export const STATUS_READ = 1
export const STATUS_SENDING = 2
export const STATUS_SENDING_FAIL = 3
