import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { USER_PERMISSION_G1 } from "@/shared/variables/UserPermissionVariables"
import { ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

const metaForm = {
    accessPermission: USER_PERMISSION_G1,
}

export default [
    {
        name: "customForms.index",
        path: "settings/custom-forms",
        component: () => import("@/pages/customForms/CustomFormsIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS),
        meta: {
            title: "Formulários customizados",
            description: "Gerencie os formulários customizados de oportunidades, empresas e pessoas.",
            accessPermission: USER_PERMISSION_G1,
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Formulários customizados",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "customForms.form",
        path: "settings/custom-forms/form/:id?",
        props: true,
        component: () => import("@/pages/customForms/CustomFormsForm"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS),
        metaForm,
        children: [
            {
                path: "configuration",
                metaForm,
                name: "customForms.form.configuration",
                component: () => import("@/pages/customForms/components/StepConfiguration"),
            },
            {
                path: "personalization",
                metaForm,
                name: "customForms.form.personalization",
                component: () => import("@/pages/customForms/components/StepPersonalization"),
            },
        ],
    },
    {
        name: "customForms.duplicate",
        path: "settings/custom-forms/duplicate/:id",
        props: true,
        component: () => import("@/pages/customForms/CustomFormsForm"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS),
        metaForm,
        children: [
            {
                path: "configuration",
                metaForm,
                name: "customForms.duplicate.configuration",
                component: () => import("@/pages/customForms/components/StepConfiguration"),
            },
            {
                path: "personalization",
                metaForm,
                name: "customForms.duplicate.personalization",
                component: () => import("@/pages/customForms/components/StepPersonalization"),
            },
        ],
    },
]
