import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { ACCOUNT_FLAG_ALLOWS_PROPOSALS } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

export default [
    {
        name: "paymentMethods.index",
        path: "settings/payment-methods",
        component: () => import("@/pages/paymentMethodsSettings/PaymentMethodsIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_PROPOSALS),
        meta: {
            title: "Formas de pagamento",
            description: "Gerencie as formas de pagamento que serão utilizadas nas propostas das oportunidades.",
            accessPermission: "G1",
            breadcrumb: [
                ...SettingsBreadcrumbs,
                {
                    label: "Formas de pagamento",
                    show: ".*",
                },
            ],
        },
    },
]
