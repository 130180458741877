import environment from "@/shared/environment"
import { ACCOUNT_FLAG_ALLOWS_DOCUMENTS_SIGNATURE } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

export default [
    {
        name: "signatures.index",
        path: "signatures",
        component: () => import("@/pages/signatures/SignaturesIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_DOCUMENTS_SIGNATURE),
        meta: {
            title: "Assinaturas",
            description: "Gerencie os documentos de propostas para assinatura.",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Assinaturas",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "signatures.view",
        path: "signatures/view/:signatureHash",
        component: () => import("@/pages/signatures/components/SignatureView"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_DOCUMENTS_SIGNATURE),
        meta: {
            pageTitle: "Assinaturas",
            title: "Visualizar documento",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Assinaturas",
                    href: "/v2/signatures",
                    show: ".*",
                },
                {
                    label: "Visualizar documento",
                    show: ".*",
                },
            ],
        },
    },
]
