import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { ACCOUNT_FLAG_ALLOWS_PROPOSALS } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

const baseMeta = {
    title: "Papel de envolvidos na proposta",
    accessPermission: "G1",
    description: "Crie papéis para os envolvidos na proposta",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Papel de envolvidos na proposta",
            show: ".*",
        },
    ],
}

export default [
    {
        name: "settings.proposal.involved-role.index",
        path: "settings/proposal/involved-role",
        component: () => import("@/pages/proposal/involvedRole/ProposalInvolvedRoleIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_PROPOSALS),
        meta: baseMeta,
        children: [
            {
                name: "settings.proposal.involved-role.create",
                path: "create",
                component: () => import("@/pages/proposal/involvedRole/ProposalInvolvedRoleCreate"),
                meta: baseMeta,
            },
            {
                name: "settings.proposal.involved-role.edit",
                path: "edit/:id",
                props: true,
                component: () => import("@/pages/proposal/involvedRole/ProposalInvolvedRoleEdit"),
                meta: baseMeta,
            },
        ],
    },
]
