import { ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

export default [
    {
        name: "customFormsNew.publicView",
        path: "/v2/custom-form-public/:formHash?",
        component: () => import("@/pages/customForms/PublicView/CustomFormIndex"),
        meta: {
            isPublic: true,
        },
    },
    {
        name: "customFormsNew.publicPreview",
        path: "/v2/custom-form-preview/:formId?",
        component: () => import("@/pages/customForms/PublicView/CustomFormPreview"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_CUSTOM_FORMS),
        meta: {
            isPublic: false,
        },
    },
]
