import SettingsBreadcrumbs from "@/router/routes/settings/breadcrumbs/SettingsBreadcrumbs"
import { ACCOUNT_FLAG_ALLOWS_AUTHENTICATIONS } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

const routesMeta = {
    title: "Autenticações",
    accessPermission: "G1",
    breadcrumb: [
        ...SettingsBreadcrumbs,
        {
            label: "Autenticações",
            show: ".*",
        },
    ],
}

export default [
    {
        name: "openIDConnectSettings.index",
        path: "settings/openid-connect",
        component: () => import("@/pages/openIDConnectSettings/OpenIdConnectSettingsIndex"),
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_AUTHENTICATIONS),
        meta: routesMeta,
        children: [
            {
                name: "openIDConnectSettings.create",
                path: "create",
                component: () => import("@/pages/openIDConnectSettings/OpenIdConnectCreate"),
                meta: routesMeta,
            },
            {
                name: "openIDConnectSettings.edit",
                path: ":id/edit",
                props: true,
                component: () => import("@/pages/openIDConnectSettings/OpenIdConnectEdit"),
                meta: routesMeta,
            },
        ],
    },
]
