import environment from "@/shared/environment"
import { ACCOUNT_FLAG_ALLOWS_CONTENT } from "@/router/routes/settings/variables/AccountFlagsVariables"
import { onlyAllowedFlag } from "@/layouts/components/sidebar/composables/validateFeatureFlag"

const contentBreadcrumbs = [
    {
        label: "Home",
        href: environment.APP_YII,
        show: ".*",
        external: true,
    },
    {
        label: "Categorias",
        href: "/v2/content/categories",
        show: ".*",
    },
]

export const ContentDefault = [
    {
        name: "categories.index",
        path: "content/categories",
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_CONTENT),
        component: () => import("@/pages/categories/CategoryIndex"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            title: "Categorias",
            breadcrumb: [
                {
                    label: "Home",
                    href: environment.APP_YII,
                    show: ".*",
                    external: true,
                },
                {
                    label: "Categorias",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "categories.view",
        path: "content/categories/:categoryId",
        component: () => import("@/pages/categories/CategoryView"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            pageTitle: "Categorias",
            title: "Categoria: ",
            breadcrumb: [
                ...contentBreadcrumbs,
                {
                    label: "",
                    show: ".*",
                },
            ],
        },
    },
]

export const ContentFullHeight = [
    {
        name: "content.add",
        path: "content/categories/:categoryId/add",
        beforeEnter: onlyAllowedFlag(ACCOUNT_FLAG_ALLOWS_CONTENT),
        component: () => import("@/pages/content/ContentAdd"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            pageTitle: "Categorias",
            breadcrumb: [
                ...contentBreadcrumbs,
                {
                    label: "",
                    href: "/v2/content/categories/",
                    show: ".*",
                },
                {
                    label: "Nova Página",
                    show: ".*",
                },
            ],
        },
    },
    {
        name: "content.edit",
        path: "content/categories/:categoryId/:pageId/edit",
        component: () => import("@/pages/content/ContentAdd"),
        meta: {
            customConfig: "SHOW_CONTENT_MODULE",
            pageTitle: "Categorias",
            breadcrumb: [
                ...contentBreadcrumbs,
                {
                    label: "",
                    href: "/v2/content/categories/",
                    show: ".*",
                },
                {
                    label: "",
                    show: ".*",
                },
            ],
        },
    },
]
