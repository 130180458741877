import environment from "@/shared/environment"

export default [
    {
        name: "companies.view",
        path: "companies/:companyId",
        component: () => import("@/pages/companies/view/CompanyView"),
        props: true,
        beforeEnter: (to, from, next) => {
            return environment.APP_ALPHA ? next() : next({ name: "notfound", query: { errorId: 403 }})
        },
        children: [
            {
                name: "companies.view.data-list",
                path: "data-list",
                component: () => import("@/pages/companies/view/pages/CompanyDataList"),
            },
            {
                name: "companies.view.dashboard",
                path: "dashboard",
                component: () => import("@/pages/companies/view/pages/CompanyDashboard"),
            },
            {
                name: "companies.view.history",
                path: "history",
                component: () => import("@/pages/companies/view/pages/CompanyHistory"),
            },
            {
                name: "companies.view.activities",
                path: "activities",
                component: () => import("@/pages/companies/view/pages/CompanyActivity"),
            },
            {
                name: "companies.view.custom-form",
                path: "custom-form",
                component: () => import("@/pages/companies/view/pages/CompanyCustomForm"),
            },
            {
                name: "companies.view.files",
                path: "files",
                component: () => import("@/pages/companies/view/pages/CompanyFile"),
            },
            {
                name: "companies.view.person",
                path: "person",
                component: () => import("@/pages/companies/view/pages/CompanyPerson"),
            },
            {
                name: "companies.view.notes",
                path: "notes",
                component: () => import("@/pages/companies/view/pages/CompanyNote"),
            },
            {
                name: "companies.view.deals",
                path: "deals",
                component: () => import("@/pages/companies/view/pages/CompanyDeal"),
            },
            {
                name: "companies.view.proposals",
                path: "proposals",
                component: () => import("@/pages/companies/view/pages/CompanyProposal"),
            },
            {
                name: "companies.view.emails",
                path: "emails",
                component: () => import("@/pages/companies/view/pages/CompanyEmail"),
            },
            {
                name: "companies.view.calls",
                path: "calls",
                component: () => import("@/pages/companies/view/pages/CompanyCall"),
            },
        ],
    },
]
